<template>
	<main>
		<el-dialog :visible="true" @close="close">
			<template slot="title">
				<div class="title">{{query.id ? '编辑' : '创建'}}{{ title }}</div>
			</template>
			<div class="form-box">
				<el-form class="create-form" ref="formRef" :rules="rules" :model="form" label-width="100px">
					<el-form-item label="标题" prop="title">
						<el-input v-model="form.title"/>
					</el-form-item>
					<el-form-item label="图片" prop="image">
						<form-upload v-model="form.image" accept="image" :limit="1"/>
					</el-form-item>
					<el-form-item label="作者" prop="author" >
						<el-input v-model="form.author"/>
					</el-form-item>
					<el-form-item label="正文" prop="content">
						<form-editor v-model="form.content"/>
					</el-form-item>
					<div class="form-flex">
						<el-form-item label="发布状态" prop="status">
							<el-radio-group v-model="form.status">
								<el-radio v-for="(item,index) in column_option.status" :key="index" :label="item.id">{{item.title}}</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="权重" prop="sort">
							<el-input v-model="form.sort" type="number"></el-input>
						</el-form-item>
					</div>
					<div class="form-flex">
						<el-form-item label="发布时间" prop="create_time">
							<form-date-picker v-model="form.create_time" type="datetime" />
						</el-form-item>
						<el-form-item label="下架时间" prop="end_time">
							<form-date-picker v-model="form.end_time" type="datetime" />
						</el-form-item>
					</div>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close">取 消</el-button>
				<el-button type="primary" :loadin="submit_loading" @click="submit">保 存</el-button>
			</div>
		</el-dialog>
	</main>
</template>

<script>
import {article as api} from "@/api/admin";
import {MixinCreate} from "@/service/admin/mixinCreate";
export default {
	mixins:[MixinCreate],
	data(){
		return {
			api,
		}
	},
	computed:{
		rules(){
			return {
				title:[
					{required:true,message:"请输入标题"}
				],
				content:[
					{required:true,message:"请输入正文"}
				]
			}
		}
	},
}
</script>
